@import '../../../../scss/theme-bootstrap';

.gnav-submenu-link {
  display: flex;
  flex-direction: column;
  width: 100%;
  @media #{$cr19-large-up} {
    height: 100%;
  }
  &__arrow {
    transform: rotate(90deg);
    @media #{$cr19-large-up} {
      display: none;
    }
  }
  &__title-link {
    &.text-link--style-2-bold {
      align-items: center;
      display: inline-flex;
      margin-bottom: 22px;
      @media #{$cr19-large-up} {
        margin-bottom: 20px;
      }
    }
    &:has(.gnav-submenu-link__input-label:not(:checked)) {
      & ~ .gnav-submenu-link__content {
        transform: translateY(-10%);
        visibility: hidden;
        height: 0;
        transition: $gnav-ease-out-transition;
        overflow: hidden;
        opacity: 0;
        @media #{$cr19-large-up} {
          transform: none;
          visibility: unset;
          height: auto;
          opacity: 1;
          transition: none;
        }
      }
    }
    &:has(.gnav-submenu-link__input-label:checked) {
      & ~ .gnav-submenu-link__content {
        visibility: unset;
        height: auto;
        opacity: 1;
        transition: $gnav-ease-out-transition;
      }
      .gnav-submenu-link__arrow {
        transform: rotate(270deg);
      }
    }
  }
  &__title-accordion.gnav-submenu-link__title-link {
    @media #{$cr19-large-up} {
      display: none;
    }
    & + .gnav-submenu-link__title-link {
      display: none;
      @media #{$cr19-large-up} {
        display: block;
      }
    }
  }
  &__list {
    margin: 0;
    padding: 0;
  }
  &__item {
    margin-bottom: 12px;
    display: block;
    width: 100%;
    &:last-of-type {
      margin-bottom: 24px;
      @media #{$cr19-large-up} {
        margin-bottom: 20px;
      }
    }
  }
  &.has-children {
    .gnav-submenu-link__title-link {
      line-height: 1.25;
      margin-bottom: 10px;
      @media #{$cr19-large-up} {
        line-height: 1.5;
        margin-bottom: 12px;
      }
    }
  }
}
